import { CardContent } from "@/Components/ui/card"
import { Separator } from "@/Components/ui/separator"
import { lang } from "@/Hooks/Functions"
import MenuPopover from "@/Modules/MenuPopover"
import { Icon } from "@iconify/react"
import { Link, usePage } from "@inertiajs/react"
import { Img } from "react-image"

export default function UserPopover({ iconColor = "white" }) {
    const { auth } = usePage().props;
    return <MenuPopover
        positions={["bottom", "left", "right", "top"]}
        bgColor="white"
        textColor="gray"
        label={
            <div className="flex relative flex-row w-fit justify-center items-center">
                <Img
                    src={auth?.user.image}
                    width={45}
                    height={45}
                    className="aspect-square border-2 border-primary rounded-full hover:text-teal-600"
                />
                <Icon icon="mdi:chevron-down-circle" className="font-semibold text-primary absolute bottom-[-3px] left-[-3px]" />
            </div>
        }
    >
        <CardContent className="flex flex-col  py-2 justify-start items-center gap-2 min-w-[200px] max-w-[300px]">
            <Link
                className="flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600"
                href={route("profile.mine")}
            >
                {lang('My Profile')}
            </Link>
            <Separator />
            <Link
                className="flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600"
                href={route("designs.mine-index")}
            >
                {lang('My Designs')}
            </Link>
            <Separator />
            <Link
                className="flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600"
                href={route("orders.mine")}
            >
                {lang('My Orders')}
            </Link>
            <Separator />
            <Link
                className="flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600"
                href={route("cart.mine")}
            >
                {lang('My Cart')}
            </Link>
            <Separator />
            {(auth?.user?.is_admin || auth?.user?.is_manager) && <>
                <Link
                    className="flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600"
                    href={route("admin-dashboard")}
                >
                    {lang('Admin Dashboard')}
                </Link>
                <Separator />
            </>}
            <Link
                method="post"
                href={route("logout")}
                as="button"
                className="flex py-1 px-4 w-full font-semibold text-foreground hover:text-teal-600"
            >
                {lang('Logout')}
            </Link>
        </CardContent>
    </MenuPopover>
}
